import { LoadingButton } from "@mui/lab";
import { Box, Grid, Typography } from "@mui/material";

import { useEffect } from "react";
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import useAuth from "src/hooks/useAuth";
import useLocales from "src/hooks/useLocales";
import useFundForm from "./hooks/useFundForm";

const SendForm = ({ refetch, fetchData }) => {
  const { translate } = useLocales();
  const { user } = useAuth();
  const userName = user?.email;

  const { methods, onSubmit } = useFundForm(refetch, fetchData);

  const {
    setError,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;

  useEffect(() => {
    methods.setValue("note", userName);
  }, [userName]);
  return (
    <>
      <Box sx={{ mt: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <FormProvider methods={methods} onSubmit={onSubmit}>
              <Box
                sx={{
                  display: "grid",
                  columnGap: 2,
                  rowGap: 3,
                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                  },
                  mb: 2,
                }}
              >
                <RHFSelect name="wallet" label="Wallet ID">
                  <option value="ewallet">
                    {translate("userFinancial.fundTransfer.e-Wallet")}
                  </option>
                  {/* <option value="deposit_wallet">{translate("userFinancial.fundTransfer.depositWallet")}</option> */}
                </RHFSelect>
                <RHFTextField
                  type="number"
                  name="amount"
                  label={translate("userFinancial.fundTransfer.amount")}
                  onWheel={(e) => e.target.blur()}
                />
                <RHFTextField
                  name="username"
                  label={translate("userFinancial.fundTransfer.accountId")}
                />

                {/* <UsersList /> */}
                <RHFTextField
                  multiline
                  fullWidth
                  rows={1}
                  name="note"
                  // value={userName}
                  InputLabelProps={{ shrink: true }}
                  label={translate("userFinancial.fundTransfer.note")}
                />
              </Box>

              <Box sx={{ display: "flex", mt: 2 }}>
                <LoadingButton
                  type="submit"
                  loading={isSubmitting}
                  variant="contained"
                >
                  {translate("send")}
                </LoadingButton>
              </Box>
            </FormProvider>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SendForm;
