import { TableCell, TableRow } from "@mui/material";
import { capitalCase } from "change-case";
import PaginationButtons from "src/components/pagination";
import ParseDate from "src/components/parse-date";
import { BodyRow, ReportTable } from "../components";
import useReport from "../hooks/useReport";
import moment from "moment";
import Filter from "./components/filterCard";

const headers = [
  "No",
  "Sales Partner ID",
  "Name",
  "eMail",
  "Mobile",
  "Total Members",
  "Total Investment",
  "DOJ",
];

const SalePartner = ({ title, heading }) => {
  const { report, getReport, rowStart, setFilter, ...rest } = useReport(
    "salespartner",
    {
      title,
      heading,
    }
  );
  const isEmpty = !Boolean(report?.length);
  const dateAndtime = (dates) => {
    const date = moment(dates);
    const datePlus365 = date.add(365, "days");
    const formattedDate = datePlus365.format("DD/MM/YYYY");
    return <div>{formattedDate}</div>;
  };
  return (
    <>
      <Filter setFilter={setFilter} />

      <ReportTable headers={headers} isEmpty={isEmpty}>
        {report?.map((row, i) => (
          <BodyRow key={row.id}>
            <TableCell component="th" scope="row">
              {i + rowStart}
            </TableCell>

            {/* <TableCell>{row?.user?.username}</TableCell> */}
            <TableCell>{row?.username}</TableCell>
            <TableCell>
              {row?.user_profile?.first_name} {row?.user_profile?.last_name}
            </TableCell>
            <TableCell>{row?.email}</TableCell>
            <TableCell>{row?.user_profile?.mobile}</TableCell>

            <TableCell>{row?.total_members}</TableCell>
            <TableCell>{row?.total_investment}</TableCell>
            {/* <TableCell>{dateAndtime(row?.created_at)}</TableCell> */}
            <TableCell>
              <ParseDate>{row?.created_at}</ParseDate>
            </TableCell>
          </BodyRow>
        ))}
      </ReportTable>
      <PaginationButtons {...rest} />
    </>
  );
};

export default SalePartner;
