import { RHFSelect } from "src/components/hook-form";
import useAuth from "src/hooks/useAuth";
import useLocales from "src/hooks/useLocales";

const paymentTypes = [
  { label: "All", value: "all" },
  { label: "Stake purchase", value: "stake_purchase" },
  { label: "Withdrawal", value: "withdrawal" },
  { label: "Transfer out", value: "fund_transfer" },
];
const partnerTypes = [
  { label: "All", value: "all" },
  { label: "Withdrawal", value: "withdrawal" },
  { label: "Transfer out", value: "fund_transfer" },
];

const EWalletWithDrawl = () => {
  const { translate } = useLocales();
  const { user } = useAuth();
  return (
    <>
      {user.user_type === "investor" ? (
        <RHFSelect
          name="payment_type"
          label={translate("sourceType")}
          size="small"
        >
          {paymentTypes?.map(({ label, value }) => (
            <option value={value}>{label}</option>
          ))}
        </RHFSelect>
      ) : (
        <RHFSelect
          name="payment_type"
          label={translate("sourceType")}
          size="small"
        >
          {partnerTypes?.map(({ label, value }) => (
            <option value={value}>{label}</option>
          ))}
        </RHFSelect>
      )}
    </>
  );
};

export default EWalletWithDrawl;
