import { capitalCase } from "change-case";
import { RHFSelect } from "src/components/hook-form";
import useAuth from "src/hooks/useAuth";
import useLocales from "src/hooks/useLocales";
import Ternary from "../ternary";

const paymentTypes = [
  { label: "All", value: "all", name: "All" },
  { label: "Referral bonus", value: "profit_share", name: "Profit Share" },

  {
    label: "Transfer In",
    value: "partner_incentive",
    name: "Partner Incentive",
  },
  {
    label: "Level commission",
    value: "senior_partner_incentive",
    name: "Senior Partner Incentive",
  },
  {
    label: "Return on staking",
    value: "referral_bonus",
    name: "Referral Bonus",
  },
  // {
  //   label: "Return on staking",
  //   value: " senior_partner_reward",
  //   name: " Senior Partner Reward",
  // },
  {
    label: "Return on staking",
    value: "stake_surrender",
    name: "Stake Surrender",
  },
  { label: "Transfer In", value: "fund_transfer", name: "Transfer In" },
];

const EWalletPaymentTypes = () => {
  const { translate } = useLocales();
  return (
    <>
      <>
        {" "}
        <RHFSelect
          name="payment_type"
          label={translate("sourceType")}
          size="small"
        >
          {paymentTypes.map(({ value, label, name }) => (
            <option value={value}>{name}</option>
          ))}
        </RHFSelect>
      </>
    </>
  );
};

export default EWalletPaymentTypes;
