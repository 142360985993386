import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import Users from "src/components/all-users";
import { FormProvider, RHFSelect } from "src/components/hook-form";
import EWalletPaymentTypes from "src/components/payment-types/e-wallet";
import useLocales from "src/hooks/useLocales";
import { date, object, ref } from "yup";
import CustomRHFDatePicker from "./customRHFDatePicker";

export const incomeReportDefaultValues = {
  start_date: null,
  end_date: null,
  payment_type: "all",
  plan: "",
};

const getTomorrow = () => {
  const today = new Date();
  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  return tomorrow;
};

const schema = object().shape({
  start_date: date()
    .max(getTomorrow(), "Future start date not allowed")
    .nullable(),
  end_date: date()
    .min(ref("start_date"), "end date can't be before start date")
    .nullable(),
});

const Filter = ({ setFilter }) => {
  const methods = useForm({
    defaultValues: incomeReportDefaultValues,
    resolver: yupResolver(schema),
  });
  const { translate } = useLocales();
  const onSubmit = (data) => {
    const { start_date, end_date, payment_type, user_id, plan } = data;
    setFilter({
      start_date:
        start_date && new Date(start_date).toLocaleDateString("en-GB"),
      end_date: end_date && new Date(end_date).toLocaleDateString("en-GB"),
      payment_type: payment_type,
      user_id: user_id,
    });
  };

  return (
    <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(1,1fr)",
            sm: "repeat(4, 1fr)",
          },
          rowGap: 3,
          columnGap: 3,
          marginBottom: "1rem",
        }}
      >
        <CustomRHFDatePicker
          label={translate("adminFinancial.payout.pickStartDate")}
          name="start_date"
          size="small"
        />
        <CustomRHFDatePicker
          label={translate("adminFinancial.payout.pickEndDate")}
          name="end_date"
          size="small"
        />
        <Users
          label="Sale Partner ID"
          name="user_id"
          size="small"
          type="partner"
          report="admin"
        />

        <Button type="submit" variant="contained" size="medium">
          {translate("getReport")}
        </Button>
      </Box>
    </FormProvider>
  );
};

export default Filter;
