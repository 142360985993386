import { useEffect, useState } from "react";
import axiosInstance from "src/utils/axios";

const useUsersList = (type, report) => {
  const [usersList, setUsersList] = useState([]);
  const fetchData = async () => {
    const reqData = new FormData();
    if (type) {
      reqData.append("type", type);
    }
    if (report) {
      reqData.append("report", report);
    }
    try {
      const { status, data } = await axiosInstance.post(
        `/api/username/autocomplete`,
        reqData
      );
      if (status === 200) {
        setUsersList(data.data);
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  return usersList;
};

export default useUsersList;
