import { lazy } from "react";
import { Navigate } from "react-router";
import SalePartner from "src/pages/reports/sale-partner";
import Loadable from "src/routes/Loadable";

const Report = Loadable(lazy(() => import("src/pages/reports/index")));

const IncomeReport = Loadable(
  lazy(() => import("src/pages/reports/income/index"))
);

const Stake = Loadable(lazy(() => import("src/pages/reports/stake/index")));

const Withdrawal = Loadable(
  lazy(() => import("src/pages/reports/withdrwal/index"))
);

const reports = [
  {
    path: "report",
    element: <Report />,
    children: [
      {
        element: <Navigate to="builder" />,
        index: true,
      },

      {
        path: "income",
        element: (
          <IncomeReport title="Finance Report" heading="Finance Report" />
        ),
      },

      {
        path: "stake",
        element: (
          <Stake title="Investment Report" heading="Investment Report" />
        ),
      },
      {
        path: "salePartner",
        element: (
          <SalePartner
            title="Sale Partner Report"
            heading="Sale Partner Report"
          />
        ),
      },

      {
        path: "withdrawal",
        element: (
          <Withdrawal title="Withdrawal Report" heading="Withdrawal Report" />
        ),
      },
    ],
  },
];

export default reports;
