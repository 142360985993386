import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const NetworkMembers = Loadable(
  lazy(() => import("src/pages/members/network"))
);

const Investors = Loadable(lazy(() => import("src/pages/members/network")));
const MemberProfile = Loadable(lazy(() => import("src/pages/members/Profile")));

const members = [
  {
    path: "members",
    children: [
      { element: <Navigate to="network" />, index: true },
      {
        path: "network",
        element: <NetworkMembers type={"partner"} label={"Partners"} />,
      },
      {
        path: "investor",
        element: <Investors type={"investor"} label={"Customers"} />,
      },
      // {
      //   path: "holding-tank",
      //   element: <HoldingTank />,
      // },
      { path: "profile/:mid", element: <MemberProfile /> },
    ],
  },
];

export default members;
