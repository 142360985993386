const URLS = {
  builder: "business-builder-report",
  fundCredit: "fund-credit-report",
  joining: "joining-report",
  income: "income-report",
  payout: "payout-report",
  point: "point-history-report",
  sales: "sales-report",
  earners: "top-earners-report",
  poolReport: "pool-report",
  referralReport: "referral-report",
  director: "director-report",
  profilereport: "profile-report",
  rebirth: "rebirth-report",
  stake: "stake-report",
  roi: "roi-wallet-report",
  withdrawal: "payout-report",
  usersRebirth: "users-rebirth",
  salespartner: "sales-partner-report",
  transaction: "",
};

export const getUrl = (url) => URLS[url];
